<template>
    <div class="container">
        <el-form ref="formRef" :model="form" :rules="rules" label-width="auto">
            <!-- 任务名称 -->
            <div class="row_box" style="padding-bottom: 2px">
                <el-form-item label="任务名称" prop="taskName">
                    <el-input v-model.trim="form.taskName" placeholder="请输入任务名称" style="width: 400px" maxlength="200" />
                </el-form-item>
            </div>
            <!-- 数据 -->
            <div class="row_box">
                <div class="tit"><SmallTitle tit="数据筛选范围" /></div>
                <el-row :gutter="15">
                    <el-col :span="8">
                        <el-form-item label="比率" prop="ratio">
                            <el-radio-group v-model="form.ratio">
                                <el-radio-button label="1">同比</el-radio-button>
                                <el-radio-button label="2">环比</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="开始批次" prop="minBatch">
                            <el-select v-model="form.minBatch" placeholder="请选择" filterable :loading="dicts.batch.loading">
                                <el-option v-for="item in dicts.batch.data" :key="item.id" :label="item.dictValue" :value="item.id" :disabled="isBatchDisabled(item.value)" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="结束批次" prop="maxBatch">
                            <el-select v-model="form.maxBatch" placeholder="请选择" filterable :loading="dicts.batch.loading">
                                <el-option v-for="item in dicts.batch.data" :key="item.id" :label="item.dictValue" :value="item.id" :disabled="isBatchDisabled(item.value)" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="15">
                    <el-col :span="8">
                        <el-form-item label="省份" prop="province">
                            <el-select v-model="form.province" placeholder="请选择" filterable multiple collapse-tags :loading="dicts.province.loading">
                                <el-option v-for="item in dicts.province.data" :key="item.id" :label="item.dictValue" :value="item.id" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="城市" prop="city">
                            <el-select v-model="form.city" placeholder="请选择" filterable :filter-method="dicts.city.filter" multiple collapse-tags :loading="dicts.city.loading">
                                <template v-for="item in dicts.city.data" :key="item.id">
                                    <el-option :label="item.dictValue" :value="item.id" v-if="item.dictValue.includes(dicts.city.searchStr)" />
                                </template>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="15">
                    <el-col :span="8">
                        <el-form-item label="餐饮类型" prop="cuisine1">
                            <el-select v-model="form.cuisine1" placeholder="请选择" filterable multiple collapse-tags :loading="dicts.cuisine1.loading">
                                <el-option v-for="item in dicts.cuisine1.data" :key="item.id" :label="item.dictValue" :value="item.id" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="菜系类型" prop="cuisine2">
                            <el-select v-model="form.cuisine2" placeholder="请选择" filterable multiple collapse-tags :loading="dicts.cuisine2.loading">
                                <el-option v-for="item in dicts.cuisine2.data" :key="item.id" :label="item.dictValue" :value="item.id" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="菜系" prop="cuisine3">
                            <el-select v-model="form.cuisine3" placeholder="请选择" filterable :filter-method="dicts.cuisine3.filter" multiple collapse-tags :loading="dicts.cuisine3.loading">
                                <template v-for="item in dicts.cuisine3.data" :key="item.id">
                                    <el-option :label="item.dictValue" :value="item.id" v-if="item.dictValue.includes(dicts.cuisine3.searchStr)" />
                                </template>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
            <!-- KPI权重设置 -->
            <div class="row_box">
                <div class="tit"><SmallTitle tit="KPI权重设置" /></div>
                <el-table :data="weightData" border style="width: 100%" :header-cell-style="{ background: '#f5f5f5', color: '#80899b' }" show-summary :summary-method="getSummaries" :span-method="mergeRows">
                    <el-table-column prop="configCategory" label="大类" width="150" />
                    <el-table-column prop="configName" label="统计值（得分）" />
                    <el-table-column label="评分权重" width="150">
                        <template #default="scope">
                            <el-input type="number" v-model="scope.row.configValue" v-if="scope.row.enableMod === 'Y'"></el-input>
                            <span v-else>{{ scope.row.configValue }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- EGPM点位颜色区间设置 -->
            <div class="row_box">
                <div class="tit"><SmallTitle tit="EGPM点位颜色区间设置" /></div>
                <el-table :data="colorData" border style="width: 100%" :header-cell-style="{ background: '#f5f5f5', color: '#80899b' }">
                    <el-table-column prop="configCategory" label="稳定性" width="150" />
                    <el-table-column prop="configName" label="显示颜色" align="center">
                        <template #default="scope">
                            <div class="color_box" :style="{ background: scope.row.configName }"></div>
                        </template>
                    </el-table-column>
                    <el-table-column label="稳定性得分区间" width="300">
                        <template #default="scope">
                            <el-input-number v-model="scope.row.wdMin" :min="0" :max="20" />
                            <span> - </span>
                            <el-input-number v-model="scope.row.wdMax" :min="0" :max="20" />
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="submit_box">
                <el-button @click="resetForm(formRef)">重置</el-button>
                <el-button type="primary" @click="submitForm(formRef)">创建任务</el-button>
            </div>
        </el-form>
    </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import SmallTitle from "@/components/common/SmallTitle.vue";
import useConfig from "./useConfig";
import useDict from "./useDict";
import useBatchMix from "./useBatchMix";
import useSubmit from "./useSubmit";

const formRef = ref(null);
const form = reactive({
    taskName: "", // 任务名称
    ratio: "1", //	比率:1-同比;2-环比(必填)
    minBatch: null, // 最小批次
    maxBatch: null, // 最大批次
    province: [], // 省份
    city: [], // 城市
    cuisine1: [], // 餐饮类型
    cuisine2: [], // 菜系类型
    cuisine3: [], // 菜系
});
const rules = reactive({
    taskName: [{ required: true, message: "请输入任务名称", trigger: "blur" }],
    ratio: [{ required: true, message: "请选择比率", trigger: "change" }],
    minBatch: [{ required: true, message: "请选择开始批次", trigger: "change" }],
    maxBatch: [{ required: true, message: "请选择结束批次", trigger: "change" }],
});

// 权重设置
const { weightData, colorData, getSummaries, mergeRows, getConfig } = useConfig();

// 字典
const { dicts } = useDict({ form });

// 批次最小值判断
const { isBatchDisabled } = useBatchMix({ form, dicts });

// 提交操作
const { resetForm, submitForm } = useSubmit({ form, dicts, getConfig, weightData, colorData });
</script>

<style lang="scss" scoped>
.container {
    padding: 0 40px 40px 40px;
    .row_box {
        background: #fff;
        border-radius: 14px;
        padding: 20px;
        margin-bottom: 10px;
        .tit {
            padding-bottom: 12px;
            border-bottom: 1px solid #f2f2f2;
            margin-bottom: 15px;
        }
        .el-select {
            width: 100%;
        }
        .table_form {
            table {
                width: 100%;
                border-collapse: collapse;

                th,
                td {
                    border: 1px solid #ccc;
                    text-align: center;
                    padding: 6px;
                    color: #333;

                    &.back {
                        background-color: #f2f2f2;
                    }

                    &.text_red {
                        color: red;
                    }
                    .el-input {
                        width: 120px;
                    }
                }

                th {
                    background-color: #f2f2f2;
                }
            }
        }
        .color_box {
            width: 100px;
            height: 32px;
            border-radius: 4px;
            border: 1px solid #ccc;
            margin: 0 auto;
        }
    }
    .submit_box {
        display: flex;
        justify-content: center;
        padding-top: 20px;
    }
}
</style>
