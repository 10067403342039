import { h, ref } from "vue";
import { useRoute } from 'vue-router';
import { apiEgpmDictWeight } from '@/request/egpmBrandApi'

export default () => {

    const route = useRoute();
    const reportId = route.query.reportId;
    const weightData = ref([]); // 权重配置数据
    const colorData = ref([]); // 颜色配置数据

    // 计算某个字段的合并数
    function calcSpan(list = [], key) {
        list = list.reverse();
        list = list.map((item, index) => {
            // 计算大类列合并数
            item.rowspan = 1;
            item.colspan = 1;
            const pre = list[index - 1];
            if (pre && pre[key] === item[key]) {
                item.rowspan = pre.rowspan + 1;
                pre.rowspan = 0;
                pre.colspan = 0;
            }
            return item;
        });
        return list.reverse();
    }

    // 获取配置
    async function getConfig() {
        const { data } = await apiEgpmDictWeight({ reportConfigId: reportId });
        const list = data || [];
        // 权重配置数据
        weightData.value = calcSpan(
            list.filter((item) => item.configType === "YY_WEIGHT"),
            "configCategory"
        );
        // 颜色配置数据
        colorData.value = list.filter((item) => item.configType === "YY_COLOR_RANGE");
        colorData.value.map((item) => {
            const rang = item.configValue.split("-");
            item.wdMin = Number(rang[0] || 0);
            item.wdMax = Number(rang[1] || 0);
        });
    }

    // 合计
    function getSummaries() {
        // 计算configValue的总和
        let total = 0;
        weightData.value.forEach((item) => {
            total += Number(item.configValue);
        });
        return ["", "总计", h('div', { style: { color: total > 100 ? 'red' : '' } }, total)];
    }

    // 合并
    function mergeRows({ row, column }) {
        if (column.label === "大类") {
            return { rowspan: row.rowspan, colspan: row.colspan };
        } else {
            return { rowspan: 1, colspan: 1 };
        }
    }

    getConfig();

    return { weightData, colorData, getSummaries, mergeRows, getConfig }
}