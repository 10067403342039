import { apiEgpmTaskAdd } from "@/request/egpmBrandApi";
import { computed } from 'vue';
import { ElMessage } from 'element-plus';
import withLoading from '@/utils/loading';
import { useRouter, useRoute } from 'vue-router';

export default ({ form, dicts, getConfig, weightData, colorData }) => {

    const router = useRouter();
    const route = useRoute();
    const params = computed(() => {
        const res = {
            reportConfigId: route.query.reportId, // 报告id
            taskName: form.taskName, // 任务名称
            ratio: form.ratio, //	比率:1-同比;2-环比(必填)
        }
        res['minBatch'] = dicts.batch.data.find(item => item.id === form.minBatch);// 开始批次
        res['maxBatch'] = dicts.batch.data.find(item => item.id === form.maxBatch);// 结束批次
        res['province'] = dicts.province.data.filter(item => form.province.includes(item.id));// 省份
        res['city'] = dicts.city.data.filter(item => form.city.includes(item.id));// 城市
        res['cuisine1'] = dicts.cuisine1.data.filter(item => form.cuisine1.includes(item.id));// 餐饮类型
        res['cuisine2'] = dicts.cuisine2.data.filter(item => form.cuisine2.includes(item.id));// 菜系类型
        res['cuisine3'] = dicts.cuisine3.data.filter(item => form.cuisine3.includes(item.id));// 菜系
        return res;
    })

    // 重置
    function resetForm(formEl) {
        if (!formEl) return;
        formEl.resetFields();
        getConfig();// 重置权重设置
    }

    // 提交
    async function submitForm(formEl) {
        if (!formEl) return
        await formEl.validate((valid) => {
            if (!valid) return;
            // 批次区间验证
            if (params.value.minBatch.value > params.value.maxBatch.value) {
                ElMessage.error('开始批次不能大于结束批次');
                return;
            }
            // 权重评分验证
            let weightTotal = 0;
            weightData.value.forEach(item => {
                weightTotal += Number(item.configValue);
            })
            if (weightTotal !== 100) {
                ElMessage.error('评分权重总计必须为100');
                return;
            }
            // 稳定性得分区间
            if (colorData.value.some((item) => item.wdMin > item.wdMax)) {
                ElMessage.error("稳定性得分区间最小值不能大于最大值");
                return;
            }
            // const low = colorData.value.find(item => item.configCode === 'low_color_range');
            // const medium = colorData.value.find(item => item.configCode === 'medium_color_range');
            // const high = colorData.value.find(item => item.configCode === 'high_color_range')
            let colorRange = colorData.value.map(item => {
                item.configValue = item.wdMin + '-' + item.wdMax;
                return item;
            })
            send({
                ...params.value,
                weightConfig: weightData.value,
                colorRange
                // lowColorRange: low?.wdMin + '-' + low?.wdMax,
                // mediumColorRange: medium?.wdMin + '-' + medium?.wdMax,
                // highColorRange: high?.wdMin + '-' + high?.wdMax,
            })
        })
    }

    // 发送创建任务请求
    async function send(params) {
        const { code, data } = await withLoading(apiEgpmTaskAdd)(params);
        if (code !== '0') return;
        ElMessage.success('任务创建成功');
        goTaskDetail(data);
    }

    // 跳转任务详情
    function goTaskDetail(id) {
        router.replace({
            path: '/egpm/brand/details',
            query: {
                reportId: route.query.reportId,
                id
            }
        })
    }

    return { resetForm, submitForm }
}